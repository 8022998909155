<template>
  <v-autocomplete
    :loading="loading"
    :value="value ? value.uid : null"
    @change="$emit('input',$event)"
    label="Enter email"
    :items="customerList"
    item-value="uid"
    :item-text="i => `${i.first_name} ${i.last_name} (${i.email})`"
    persistent-hint
    return-object
  />
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "CustomerAutocomplete",
  props: {
    value: Object
  },
  emits: ['update:uid'],
  mounted() {
    return this.fetchCustomers()
      .then(() => this.loading = false);
  },
  methods: {
    ...mapActions(['fetchCustomers'])
  },
  computed: {
    ...mapState(['customers']),
    customerList() {
      function name(c) {
        return `${c.first_name} ${c.last_name}`;
      }
      const list = Object.values(this.customers || {})
        .filter(c => !!c.email)
        .sort((a, b) => name(a).localeCompare(name(b)));
      return list;
    }
  },
  data() {
    return {
      loading: true
    }
  }
}
</script>

<style scoped>

</style>